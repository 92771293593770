<template>
  <div>
    <!-- START STEP 1-->
    <section class="pedido-steps" v-if="step == 1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block d-sm-none">
              <router-link :to="route">
                <img src="assets/img/backspace.svg" class="mb-5">
              </router-link>
            </div>
            <div class="pasos">
              <img src="assets/img/step1.png" class="d-none d-sm-block">
              <img src="assets/img/step_mobile1.png" class="w-100 d-block d-sm-none">
            </div>
            <div class="section-title">
              <h4>{{ $t("title") }}</h4>
              <router-link :to="route">
                <img class="d-none d-sm-block" src="assets/img/backspace.svg">
              </router-link>
            </div>
            <p class="subtitulo">{{ $t("description") }}</p>
          </div>
        </div>
        <form :action="route + 'pedido-confirmado'" method="GET" id="form-id">
          <div class="row">
            <div class="col-12">
              <div class="form-group" id="lugar">
                <label>{{ $t("ship_to") }}*</label>
                <VueMultiselect
                    v-model="data.lugar_entrega"
                    :options="shiptos"
                    :value="shiptos.ship_to"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :internal-search="true"
                    :searchable="true"
                    label="name"
                    track-by="ship_to"
                    :select-label="'Select'"
                    :selected-label="'Selected'"
                    :deselect-label="'Deselect'"
                    :allow-empty="true"
                    class="custom-select-shiptos"
                    @select="onSelectShipTo"
                    :disabled="country === 'Colombia' && data.lugar_entrega"
                />
                <span class="required-span d-none">{{ $t("ship_to_mandatory") }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6" v-if="enableIncoterm">
              <div class="form-group" id="incoterm">
                <label>{{ $t("incoterm") }} *</label>
                <select name="incoterm" @change="getFechas()" v-model="data.incoterm" :disabled="this.lockStep1Fields">
                  <option v-for="(incoterm,index) in this.incoterms" :key="index"
                          :value="incoterm.code + ' - ' + incoterm.name">{{ incoterm.description }}
                  </option>
                </select>
                <span class="required-span d-none">{{ $t("incoterm_mandatory") }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" id="planta">
                <label>{{ $t("plant") }} *</label>
                <select name="planta" v-model="data.planta" @change="getFechas()"
                        :disabled="this.lockStep1Fields || validateCountries('argentina') || validateCountries('colombia')">
                  <option v-for="(plant,index) in this.plants" :key="index" :value="plant.id">{{ plant.id }} -
                    {{ plant.name }}
                  </option>
                </select>
                <span class="required-span d-none">{{ $t("plant_mandatory") }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" id="fecha">
                <label>{{ $t("delivery_date") }} *</label>
                <Datepicker
                    v-model="data.fecha"
                    name="fecha-entrega"
                    :minDate="start_date"
                    :maxDate="end_date"
                    :enableTimePicker="false"
                    :disabledWeekDays="fechas.block_these_days"
                    textInput
                    autoApply
                    format="dd/MM/yyyy"
                    inputClassName="dp-input-custom"
                    :startDate="start_date"
                    :disabled="lockStep1Fields || disableDP"
                    required
                    utc="preserve"
                />
                <span class="required-span d-none">{{ $t("delivery_date_mandatory") }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" id="order">
                <label>{{ $t("purchase_order") }}</label>
                <input type="text" id="input-purchase-order" name="order-id"
                       v-model="data.purchase_order" v-on:keyup="validatePO(data.purchase_order)"
                       maxlength="34" :disabled="this.lockStep1Fields">
                <span class="required-span d-none">{{ $t("purchase_order_mandatory") }}</span>
                <span class="invalid-span d-none">{{ $t("purchase_order_invalid") }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- <a class="btn-yellow pull-left" @click="cargarComprobante()"  href="javascript:void(0)">
                  Cargar comprobante de pago
                  <img src="assets/img/upload_24px.svg" class="pull-right">
              </a>
              <input type="file" id="cargarComprobante" class="d-none"> -->
            </div>
            <div class="col-md-6" v-if="this.user.consolidar_carga">
              <div class="form-group consolidar-carga" id="consolidar__carga">
                <label>{{ $t("consolidar_carga") }}</label>
                <input type="checkbox" v-model="data.consolidar_carga" name="consolidar_carga" id="consolidar_carga"
                       :disabled="this.lockStep1Fields">
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-md-12 next-btn">
            <a href="javascript:void(0)" @click="validateStep1()" class="btn-green">{{ $t("buttons.continue") }}</a>
            <a @click="cancelOrder()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
          </div>
        </div>
      </div>
    </section>
    <!-- END STEP 1-->


    <!-- START STEP 2-->

    <section class="pedido-steps" v-if="step == 2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block d-sm-none">
              <a href="javascript:void(0)" @click="step = 1"><img src="assets/img/backspace.svg" class="mb-5"></a>
            </div>
            <div class="pasos">
              <img src="assets/img/step2.png" class="d-none d-sm-block">
              <img src="assets/img/step_mobile2.png" class="w-100 d-block d-sm-none">
            </div>
            <div class="section-title">
              <h4>{{ $t("add_product") }}</h4>
              <a href="javascript:void(0)" @click="step = 1"><img class="d-none d-sm-block"
                                                                  src="assets/img/backspace.svg"></a>
            </div>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-xs-12 col-md-8">
              <div class="form-group" id="producto">
                <label>{{ $t("select_product") }}</label>
                <VueMultiselect
                    v-model="this.selected_sku"
                    :options="this.skus"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :internal-search="true"
                    :placeholder="$t('type_to_search')"
                    :custom-label="getProductLabel"
                    track-by="id"
                    :searchable="true"
                    @select="onSelectSku"
                    :select-label="''"
                    :selected-label="''"
                    :deselect-label="''"
                    :allow-empty="true"
                />
                <span class="required-span d-none">{{ $t("all_fields_mandatory") }}</span>
              </div>
            </div>
            <div class="col-xs-4 col-md-4" v-if="this.country !== 'Colombia'">
              <div class="form-group">
                <label v-if="this.isProductCase"><span class="palletQty">{{ this.caseByPallet }}</span> {{
                    $t("cases")
                  }} (1 pallet) <span class="info-circle" v-if="selected_sku?.id" @click="showCuota()"></span></label>
                <label v-if="!this.isProductCase"><span class="palletQty">{{ this.eachByCase }}</span> {{ $t("each") }}
                  (1 {{ $t("case") }}) | <span class="palletQty">{{ this.caseByPallet }} {{
                      $t("cases")
                    }} (1 pallet)</span><span class="info-circle" v-if="selected_sku?.id"
                                              @click="showCuota()"></span></label>
                <div class="inline-flex align-center">
                  <input
                      type="text"
                      id="palletCount"
                      name="palletCount"
                      value="1"
                      v-on:keydown="handleKeydown"
                      class="counter-input palletCount"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-4 col-md-4" v-if="this.country === 'Colombia'">
              <div class="form-group">
                <label v-if="isProductCase">
                  {{ $t("cases") }}
                  <span class="info-circle" v-if="selected_sku?.id" @click="showCuota()"></span>
                </label>
                <label v-if="!isProductCase">
                  {{ $t("each") }}
                  <span class="info-circle" v-if="selected_sku?.id" @click="showCuota()"></span>
                </label>
                <div class="inline-flex align-center">
                  <select
                      v-if="!isProductCase"
                      v-model="selectedEach"
                      id="eachByCaseSelect"
                      name="eachByCaseSelect"
                      class="counter-input palletCount"
                  >
                    <option v-for="option in qtyOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <select
                      v-else
                      v-model="selectedCase"
                      id="caseByPalletSelect"
                      name="caseByPalletSelect"
                      class="counter-input palletCount"
                  >
                    <option v-for="option in qtyOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12 next-btn">
              <a
                  href="javascript:void(0)"
                  @click="addCart()"
                  class="btn-green pull-left m-0 mt-2"
                  ref="addCartButton"
              >
                {{ $t("buttons.add") }}
              </a>
            </div>
          </div>
        </form>
        <div class="divisorline"></div>
        <div class="section-title">
          <h5>{{ $t("order_resume") }}</h5>
        </div>
        <div class="loading"></div>
        <div id="mostrarTablaPedidos" class="d-none" v-if="this.cart">

          <!--Para desktop-->
          <table id="resumenPedidos" class="d-none d-sm-table">
            <tr>
              <th>EAN</th>
              <th>{{ $t("name") }}</th>
              <th>Material</th>
              <th>{{ $t("weight_gross") }}</th>
              <th>{{ $t("weight_net") }}</th>
              <th>{{ $t("volume") }}</th>
              <th>{{ $t("quantity") }}</th>
              <th>{{ $t("type") }}</th>
              <th># Pallet</th>
              <th>{{ $t("buttons.delete") }}</th>
            </tr>
            <tr class="b-top" v-for="(item,index) in this.cart.items" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.material }}</td>
              <td>{{ item.weight_bruto }}</td>
              <td>{{ item.number_of_units }}</td>
              <td>{{ item.volume }}</td>
              <td>
                <div class="inline-flex align-center" :id="item.id" v-if="this.country !== 'Colombia'">
                  <input type="text" class="counter-input"
                         v-model="item.qty" v-on:keydown="validateQuantity" @change="updateCart(item)">
                  <input type="hidden" class="counter-input"
                         v-model="item.qty_original">
                  <span class="counter refresh" @click="updateCart(item)">
                    <img src="assets/img/refresh.png" alt="refresh">
                  </span>
                </div>
                <div class="inline-flex align-center" :id="item.id" v-if="this.country === 'Colombia'">
                  <select
                      v-if="item.product_type === 'EA'"
                      v-model="item.qty"
                      :id="'eachByCaseSelect-' + item.id"
                      :name="'eachByCaseSelect-' + item.id"
                      class="counter-input palletCount"
                      @change="updateCart(item)"
                  >
                    <option v-for="option in item.sku_options_qty" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <select
                      v-else
                      v-model="item.qty"
                      :id="'caseByPalletSelect-' + item.id"
                      :name="'caseByPalletSelect-' + item.id"
                      class="counter-input palletCount"
                      @change="updateCart(item)"
                  >
                    <option v-for="option in item.sku_options_qty" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <input type="hidden" class="counter-input"
                         v-model="item.qty_original">
                  <span class="counter refresh" @click="updateCart(item)">
                    <img src="assets/img/refresh.png" alt="refresh">
                  </span>
                </div>
              </td>
              <td>{{ item.package_format }}</td>
              <td>{{ item.pallet_numbers }}</td>
              <td @click="removeCart(item.id)"><img class="pointer" src="assets/img/delete.svg"></td>
            </tr>
          </table>

          <!--Para mobile hay que hacer una tabla por pedido-->
          <table class="d-block d-sm-none resumenPedidosMobile b-top" v-for="(item,index) in this.cart.items"
                 :key="index">
            <tr>
              <th>EAN</th>
              <th>{{ $t("name") }}</th>
              <th>Material</th>
              <th>{{ $t("weight_gross") }}</th>
              <th>{{ $t("weight_net") }}</th>
              <th>{{ $t("volume") }}</th>
              <th>{{ $t("quantity") }}</th>
              <th>{{ $t("type") }}</th>
              <th># Pallet</th>
              <th>{{ $t("buttons.delete") }}</th>
            </tr>
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.material }}</td>
              <td>{{ item.weight_bruto }}</td>
              <td>{{ item.number_of_units }}</td>
              <td>{{ item.volume }}</td>
              <td class="table-input-qty">
                <div class="inline-flex align-center" :id="item.id" v-if="this.country !== 'Colombia'">
                  <input type="text" class="counter-input"
                         v-model="item.qty" v-on:keydown="validateQuantity" @change="updateCart(item)">
                  <input type="hidden" class="counter-input"
                         v-model="item.qty_original">
                  <span @click="updateCart(item)" class="counter refresh">
                                      <img src="assets/img/refresh.png" alt="refresh">
                                    </span>
                </div>
                <div class="inline-flex align-center" :id="item.id" v-if="this.country === 'Colombia'">
                  <select
                      v-if="item.product_type === 'EA'"
                      v-model="item.qty"
                      :id="'eachByCaseSelect-' + item.id"
                      :name="'eachByCaseSelect-' + item.id"
                      class="counter-input palletCount"
                      @change="updateCart(item)"
                  >
                    <option v-for="option in item.sku_options_qty" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <select
                      v-else
                      v-model="item.qty"
                      :id="'caseByPalletSelect-' + item.id"
                      :name="'caseByPalletSelect-' + item.id"
                      class="counter-input palletCount"
                      @change="updateCart(item)"
                  >
                    <option v-for="option in item.sku_options_qty" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <input type="hidden" class="counter-input"
                         v-model="item.qty_original">
                  <span @click="updateCart(item)" class="counter refresh">
                                      <img src="assets/img/refresh.png" alt="refresh">
                                    </span>
                </div>
              </td>
              <td>{{ item.package_format }}</td>
              <td>{{ item.pallet_numbers }}</td>
              <td class="table-delete-button" @click="removeCart(item.id)"><img class="pointer"
                                                                                src="assets/img/delete.svg"></td>
            </tr>
          </table>

          <div class="row mt-3" v-if="this.cart.total_pallets > 0"
               :class="{ 'w-80p': !validateCountries('argentina') }">
            <div class="col-md-3" :class="{ 'mw-22p': validateCountries('argentina') }">
              <div class="form-group">
                <span v-if="(this.medicion === 'metric')">{{ $t("total_weight_net") }}: {{ this.cart.subtotal.weight }} kg</span>
                <span v-else>{{ $t("total_weight_net") }}: {{ this.cart.subtotal.imperial_weight }} lb</span>
              </div>
            </div>
            <div class="col-md-3" :class="{ 'mw-22p': validateCountries('argentina') }">
              <div class="form-group">
                <span v-if="(this.medicion === 'metric')">{{
                    $t("total_weight_gross")
                  }}: {{ this.cart.subtotal.weightB }} kg</span>
                <span v-else>{{ $t("total_weight_gross") }}: {{ this.cart.subtotal.imperial_weightB }} lb</span>
              </div>
            </div>
            <div class="col-md-3" :class="{ 'mw-22p': validateCountries('argentina') }">
              <div class="form-group">
                <span v-if="(this.medicion === 'metric')">{{
                    $t("total_volume")
                  }}: {{ this.cart.subtotal.volume }} m<sup>3</sup></span>
                <span v-else>{{ $t("total_volume") }}: {{ this.cart.subtotal.imperial_volume }} ft<sup>3</sup></span>
              </div>
            </div>
            <div class="col-md-2" v-if="validateCountries('argentina')">
              <div class="form-group">
                <span>{{ $t("total_cajas") }}: {{ this.cart.subtotal.total_quantity }} </span>
              </div>
            </div>
            <div class="col-md-2" v-if="validateCountries('argentina')">
              <div class="form-group">
                <span>{{ $t("total_pallets") }}: {{ this.cart.total_pallets }} </span>
              </div>
            </div>
          </div>
          <div class="row w-80p" v-if="this.cart.total_pallets > 0">
            <div class="col-xs-6 col-md-4">
              <div class="form-group inline-flex">
                <input type="radio" name="medicion" :checked="this.medicion === 'metric'"
                       @click="cambiarMedicion('metric')">
                <label class="ml-2">{{ $t("metric_system") }}</label>
              </div>
            </div>
            <div class="col-xs-6 col-md-4">
              <div class="form-group inline-flex">
                <input type="radio" :checked="this.medicion === 'imperial'" name="medicion"
                       @click="cambiarMedicion('imperial')">
                <label class="ml-2">{{ $t("imperial_system") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 next-btn mt-5 mb-5">
            <a @click="validateStep2()" href="javascript:void(0)" class="btn-green mr-0 ml-2">{{
                $t("btn_checkout")
              }}</a>
            <a @click="cancelOrder()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
          </div>
        </div>
      </div>
    </section>

    <!-- END STEP 2-->

    <!-- START STEP 3-->

    <section class="pedido-steps" v-if="step == 3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block d-sm-none">
              <a href="javascript:void(0)" @click="goStep2()"><img src="assets/img/backspace.svg" class="mb-5"></a>
            </div>
            <div class="pasos">
              <img src="assets/img/step3.png" class="d-none d-sm-block">
              <img src="assets/img/step_mobile4.png" class="w-100 d-block d-sm-none">
            </div>
            <div class="section-title">
              <h4>{{ $t("confirmation") }}</h4>
              <a href="javascript:void(0)" @click="goStep2()"><img class="d-none d-sm-block"
                                                                   src="assets/img/backspace.svg"></a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="confirmado ">
              <div class="section-title p-0">
                <h5>{{ $t("resume_title") }}</h5>
              </div>
              <!-- Desktop -->
              <table class="bg-white  d-none d-sm-table">
                <tr>
                  <th>EAN</th>
                  <th>{{ $t("name") }}</th>
                  <th>Material</th>
                  <th>{{ $t("weight_gross") }}</th>
                  <th>{{ $t("weight_net") }}</th>
                  <th>{{ $t("volume") }}</th>
                  <th>{{ $t("quantity") }}</th>
                  <th>{{ $t("type") }}</th>
                  <th># Pallet</th>
                </tr>
                <tr class="b-top" v-for="(item,index) in this.cart.items" :key="index">
                  <td>{{ item.id }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.material }}</td>
                  <td>{{ item.weight_bruto }}</td>
                  <td>{{ item.number_of_units }}</td>
                  <td>{{ item.volume }}</td>
                  <td>{{ item.qty }}</td>
                  <td>{{ item.package_format }}</td>
                  <td>{{ item.pallet_numbers }}</td>
                </tr>
              </table>

              <!-- Mobile -->
              <div class="pedidosDetalle">
                <table class="bg-white d-block d-sm-none resumenPedidosMobile border-gray"
                       v-for="(item,index) in this.cart.items" :key="index">
                  <tr>
                    <th>EAN</th>
                    <th>{{ $t("name") }}</th>
                    <th>Material</th>
                    <th>{{ $t("weight_gross") }}</th>
                    <th>{{ $t("weight_net") }}</th>
                    <th>{{ $t("volume") }}</th>
                    <th>{{ $t("quantity") }}</th>
                    <th>{{ $t("type") }}</th>
                    <th># Pallet</th>
                  </tr>
                  <tr>
                    <td>{{ item.id }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.material }}</td>
                    <td>{{ item.weight_bruto }}</td>
                    <td>{{ item.number_of_units }}</td>
                    <td>{{ item.volume }}</td>
                    <td>{{ item.qty }}</td>
                    <td>{{ item.package_format }}</td>
                    <td>{{ item.pallet_numbers }}</td>
                  </tr>
                </table>
              </div>

              <div class="yellow-bg" :class="{ 'yellow-p-20': validateCountries('argentina') }">
                <div class="inline-table">
                  <span class="pull-left">{{ $t("delivery_date") }}: <b>{{ this.parseDate(this.data.fecha) }}</b></span>
                  <span class="pull-right" v-if="enableIncoterm">{{
                      $t("incoterm")
                    }}: <b>{{ this.data.incoterm }}</b></span>
                </div>
                <div>
                  <span class="pull-left w-100">{{ $t("ship_to") }}: <b>{{ this.data.lugar_entrega }}</b></span>
                </div>
                <div class="row mt-5">
                  <div class="col-md-3" :class="{ 'mw-22p': validateCountries('argentina') }">
                    {{ $t("total_weight_net") }}: <br class="d-block d-sm-none"><b> {{
                      (this.medicion === 'metric')
                          ? this.cart.subtotal.weight + ' kg'
                          : this.cart.subtotal.imperial_weight + ' lb'
                    }}</b>
                  </div>
                  <div class="col-md-3" :class="{ 'mw-22p': validateCountries('argentina') }">
                    {{ $t("total_weight_gross") }}: <br class="d-block d-sm-none"><b> {{
                      (this.medicion === 'metric')
                          ? this.cart.subtotal.weightB + ' kg'
                          : this.cart.subtotal.imperial_weightB + ' lb'
                    }}</b>
                  </div>
                  <div class="col-md-3" :class="{ 'mw-22p': validateCountries('argentina') }">
                    {{ $t("total_volume") }}: <br class="d-block d-sm-none"><b> {{
                      (this.medicion === 'metric')
                          ? this.cart.subtotal.volume + ' m'
                          : this.cart.subtotal.imperial_volume + ' ft'
                    }}<sup>3</sup></b>
                  </div>
                  <div class="col-md-2" v-if="validateCountries('argentina')">
                    {{ $t("total_cajas") }}: <br
                      class="d-block d-sm-none"><b>{{ this.cart.subtotal.total_quantity }}</b>
                  </div>
                  <div
                      :class="{'col-md-3': !validateCountries('argentina'), 'col-md-2': validateCountries('argentina') }">
                    {{ $t("total_pallets") }}: <br class="d-block d-sm-none"><b>{{ this.cart.total_pallets }}</b>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 next-btn mt-5 mb-4 mr-4">
                  <button :disabled="submit_disabled" @click="validateStep3()" href="javascript:void(0)"
                          class="btn-green mr-0 ml-2 pointer">{{ $t("buttons.confirm") }}
                  </button>
                  <a @click="cancelOrder()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <PedidoListo :step="step" :order_num="this.data.order_num"></PedidoListo>

  </div>
  <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
</template>

<script>
import {route} from "@/enviroment";
import $ from 'jquery';
import axiosInstance from '../../main';
import PedidoListo from './PedidoListo.vue';
import TermsAndCond from "./TermsAndCond.vue";
import authMixin from '@/mixins/authMixin';

export default {
  name: 'PedidoNuevo',
  data: function () {
    return {
      usuario: '',
      password: '',
      route: route,
      step: 1,
      data: {
        lugar_entrega: '',
        incoterm: '',
        planta: '',
        fecha: '',
        purchase_order: '',
        order_num: '',
        consolidar_carga: false
      },
      submit_disabled: false,
      shiptos: [],
      incoterms: [],
      plants: [],
      skus: [],
      fechas: [],
      start_date: '',
      end_date: '',
      allowedDates: [],
      selected_sku: [],
      cart: [],
      medicion: 'imperial',
      user: [],
      lockStep1Fields: false,
      isProductCase: true,
      eachByCase: 0,
      caseByPallet: 0,
      enableIncoterm: true,
      sold_to: null,
      selectedEach: null,
      selectedCase: null,
      country: '',
    }
  },
  i18n: {
    messages: {
      en: {
        title: "Order Creation",
        description: "In this section, you can create your order to be reviewed by McCain before submission.",
        cart_empty: "Cart is empty.",
        cancel_order: "Cancel Order",
        confirm_cancel_order_text: "Are you sure you want to cancel the order?",
        cuota_info_modal_title: "Quota Detail",
        ship_to: "Delivery Location",
        ship_to_mandatory: "Delivery Location is mandatory",
        incoterm: "Incoterm",
        incoterm_mandatory: "Incoterm is mandatory",
        plant: "Plant",
        plant_mandatory: "Plant is mandatory",
        delivery_date: "Delivery Date",
        delivery_date_mandatory: "Delivery date is mandatory",
        purchase_order: "Purchase Order",
        purchase_order_mandatory: "Purchase Order is mandatory",
        purchase_order_invalid: "Only letters, numbers and spaces are allowed. Maximum 34 characters.",
        consolidar_carga: "Consolidate Load",
        add_product: "Add Products",
        select_product: "Select a product",
        all_fields_mandatory: "All fields are mandatory",
        cases: "Cases",
        case: "Case",
        each: "Bags",
        order_resume: "Order Summary",
        name: "Name",
        weight_gross: "Gross Weight",
        weight_net: "Net Weight",
        volume: "Volume",
        quantity: "Quantity",
        type: "Type",
        total_weight_gross: "Total Gross Weight",
        total_weight_net: "Total Net Weight",
        total_volume: "Total Volume",
        total_pallets: "Total Pallets",
        total_cajas: "Total Boxes",
        metric_system: "Metric System",
        imperial_system: "Imperial System",
        btn_checkout: "Place Order",
        confirmation: "Confirmation",
        resume_title: "Order Summary",
        type_to_search: "Type to search"
      },
      es: {
        title: "Creación de pedido",
        description: "En esta sección puede crear su pedido para previamente ser enviado a McCain para revisión.",
        cart_empty: "Carrito vacío.",
        cancel_order: "Cancelar orden",
        confirm_cancel_order_text: "Está seguro que desea cancelar la orden?",
        cuota_info_modal_title: "Detalle cuota",
        ship_to: "Lugar de entrega",
        ship_to_mandatory: "Lugar de entrega es obligatorio",
        incoterm: "Incoterm",
        incoterm_mandatory: "Incoterm es obligatorio",
        plant: "Planta",
        plant_mandatory: "Planta es obligatorio",
        delivery_date: "Fecha requerida de entrega",
        delivery_date_mandatory: "La fecha de entrega es obligatoria",
        purchase_order: "Purchase order",
        purchase_order_mandatory: "Purchase Order es obligatorio",
        purchase_order_invalid: "Solo se permiten letras, números y espacios. Máximo 34 caracteres.",
        consolidar_carga: "Consolidar Carga",
        add_product: "Agregar productos",
        select_product: "Seleccionar un producto",
        all_fields_mandatory: "Todos los campos son obligatorios",
        cases: "Cajas",
        case: "Caja",
        each: "Bolsas",
        order_resume: "Resumen del pedido",
        name: "Nombre",
        weight_gross: "Peso Bruto",
        weight_net: "Peso Neto",
        volume: "Volumen",
        quantity: "Cant",
        type: "Tipo",
        total_weight_gross: "Peso Total Bruto",
        total_weight_net: "Peso Total Neto",
        total_volume: "Volumen Total",
        total_pallets: "Total Pallets",
        total_cajas: "Total Cajas",
        metric_system: "Sistema métrico",
        imperial_system: "Sistema imperial",
        btn_checkout: "Realizar Pedido",
        confirmation: "Confirmación",
        resume_title: "Resumen del pedido",
        type_to_search: "Escriba para buscar"
      }
    }
  },
  components: {
    PedidoListo
  },
  mixins: [authMixin],
  async created() {
    const valid = await this.validateUserAndSoldTo();
    if (valid) {
      await this.getCountry();
      await this.getUser();
      this.getShipTos();
      this.getIncoterms();
      this.getCart();
    }
  },
  computed: {
    qtyOptions() {
      let options = [];
      const packageFormat = this.selected_sku?.package_format?.toString().toUpperCase() ?? '';
      const sellingQuantities = this.parseSellingQuantities(this.selected_sku?.selling_quantities);
      const dropdownBy = this.selected_sku?.dropdown_quantities_by?.toString().toUpperCase() ?? '';
      if (['EA', 'CS'].includes(packageFormat) && sellingQuantities > 0 && dropdownBy === 'MULTIPLO') {
        for (let i = sellingQuantities; i <= 1000; i += sellingQuantities) {
          options.push(i);
        }
      } else {
        options.push(...Array.from({ length: 100 }, (_, i) => i + 1));
      }
      return options;
    },
    disableDP: function () {
      if (this.enableIncoterm) {
        return !(this.data.lugar_entrega != '' && this.data.planta != '' && this.data.incoterm != '');
      } else {
        return !(this.data.lugar_entrega != '' && this.data.planta != '');
      }
    }
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    getCountry() {
      this.country = this.$cookies.get("pwa-woe-country");
    },
    cargarComprobante() {
      $('#cargarComprobante').trigger('click');
      $('#cargarComprobante').removeClass('d-none');
    },
    async cancelOrder() {
      const confirmed = await this.cancelConfirm();
      if (!confirmed) {
        return;
      }
      axiosInstance.get(this.$woe_api_uri + '/cart/cancel-order')
          .then(() => {
            location.href = route;
          })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
          });
    },
    getCart() {
      axiosInstance.get(this.$woe_api_uri + '/cart')
          .then((response) => {
            if (response.data.data !== "") {
              this.cart = response.data.data;
              this.fillStep1Fields();
            }

            if(this.country === 'Colombia') {
              this.cart.items = this.cart.items.map(item => {
                const skuOptionsQty = [];
                const packageFormat = item.package_format?.toString().toUpperCase() ?? '';
                const sellingQuantities = this.parseSellingQuantities(item.selling_quantities);
                const dropdownBy = item.dropdown_quantities_by?.toString().toUpperCase() ?? '';
                if (['EA', 'CS'].includes(packageFormat) && sellingQuantities > 0 && dropdownBy === 'MULTIPLO') {
                  for (let i = sellingQuantities; i <= 1000; i += sellingQuantities) {
                    skuOptionsQty.push(i);
                  }
                } else {
                  skuOptionsQty.push(...Array.from({ length: 100 }, (_, i) => i + 1));
                }
                return {
                  ...item,
                  sku_options_qty: [...skuOptionsQty],
                };
              });
            }

            this.ocultarCarga();
          })
          .catch((error) => {
            this.showAlert(
                'warning',
                `${this.$t('caution')}!`,
                error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`
            );
          });
    },
    fillStep1Fields() {
      this.lockStep1Fields = this.cart.items.length > 0;
      if (this.cart.selected.ship_to) {
        const shipTo = this.shiptos.find(ele => ele.ship_to === this.cart.selected.ship_to);
        if (shipTo) this.data.lugar_entrega = shipTo;
      }
      if (this.cart.selected.incoterm) this.data.incoterm = this.cart.selected.incoterm;
      if (this.cart.selected.delivery_date) this.data.fecha = this.cart.selected.delivery_date;
      if (this.cart.selected.purchase_order) this.data.purchase_order = this.cart.selected.purchase_order;
      if (this.cart.selected.consolidar_carga) this.data.consolidar_carga = this.cart.selected.consolidar_carga;
      if (this.cart.selected.plant_id) this.data.planta = this.cart.selected.plant_id;

      if (this.data.lugar_entrega) this.getPlants();
    },
    mostrarCarga() {
      $('.loading').removeClass('d-none');
      $('#mostrarTablaPedidos').addClass('d-none');
    },
    ocultarCarga() {
      $('.loading').addClass('d-none');
      $('#mostrarTablaPedidos').removeClass('d-none');
    },
    addCart() {
      //Valido que haya seleccionado un producto
      var producto = $('#producto-seleccionado').find(":selected").val();
      if (producto != "") {

        this.mostrarCarga()

        $('#producto .required-span').addClass('d-none')
        $('#producto select').removeClass('required')

        let pallet_qty = $(".palletCount").val();
        axiosInstance.post(this.$woe_api_uri + '/cart/add',
            {
              "id": this.selected_sku?.id,
              "qty": pallet_qty,
              "ship_to": this.data.lugar_entrega,
              "plant_id": this.data.planta,
              "delivery_date": this.data.fecha,
              "incoterm": this.data.incoterm,
            }
        ).then(() => {
          this.getCart()
          this.selected_sku = [];
          $(".palletCount").val('');
        })
            .catch((error) => {
              this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
              $('.loading').addClass('d-none');
              $('#mostrarTablaPedidos').removeClass('d-none');
            });

      } else {
        $('#producto .required-span').removeClass('d-none')
        $('#producto select').addClass('required')
      }

    },
    updateCart(item) {
      this.mostrarCarga();
      axiosInstance.put(this.$woe_api_uri + '/cart/update',
          {
            "id": item.id,
            "qty": item.qty,
            "qty_original": item.qty_original,
            "ship_to": this.data.lugar_entrega,
            "plant_id": this.data.planta,
            "delivery_date": this.data.fecha,
            "incoterm": this.data.incoterm,
          }
      ).then(() => {
      })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
          }).finally(() => {
        this.getCart()
      });
    },
    removeCart(id) {
      this.mostrarCarga()
      axiosInstance.post(this.$woe_api_uri + '/cart/remove',
          {
            "id": id,
          }
      ).then(() => {
        this.getCart()
      })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
            this.ocultarCarga()
          });
    },
    onSelectSku(sku) {
      this.selected_sku = sku;
      this.isProductCase = this.selected_sku.package_format !== 'EA';
      this.caseByPallet = this.parsePalletQty(this.selected_sku?.pallet_qty);
      this.eachByCase = this.parseSellingQuantities(this.selected_sku?.selling_quantities);
    },
    parsePalletQty(qty) {
      const palletQty = parseInt(qty, 10);
      return !isNaN(palletQty) && palletQty > 0 ? palletQty : 1;
    },
    parseSellingQuantities(quantities) {
      const sellingQuantities = parseInt(quantities, 10);
      return !isNaN(sellingQuantities) && sellingQuantities > 0 ? sellingQuantities : 1;
    },
    onSelectShipTo(selected) {
      this.selectedShipto = selected.ship_to;
      if (this.selectedShipto) this.getPlants();
    },
    getPlants() {
      if (!this.selectedShipto) return;
      axiosInstance.post(this.$woe_api_uri + '/catalog/plants',
          {
            ship_to: this.selectedShipto
          }
      ).then((response) => {
        if (response.data.data.plants.length > 0) {
          if (['argentina', 'colombia'].includes(sessionStorage.getItem('country').toLowerCase())) {
            this.plants = [response.data.data.plants[0]];
            this.data.planta = this.plants[0].id;
          } else this.plants = response.data.data.plants;
        }
        this.getFechas()
      })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
          });
    },
    getFechas() {
      axiosInstance.post(this.$woe_api_uri + '/catalog/calendar',
          {
            "ship_to": this.selectedShipto,
            "plant_id": this.data.planta,
            "incoterm": this.data.incoterm
          }
      ).then((response) => {
        this.fechas = response.data.data.calendar;
        this.start_date = this.fechas.start_date;
        this.end_date = this.fechas.end_date;
      })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
          });
    },
    cambiarMedicion(medicion) {
      this.medicion = medicion
      var $box = $(this);
      if ($box.is(":checked")) {
        var group = "input:checkbox[name='" + $box.attr("medicion") + "']";
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    },
    getSkus() {
      axiosInstance.post(this.$woe_api_uri + '/catalog/skus',
          {
            'ship_to': this.selectedShipto,
            'plant_id': this.data.planta,
            'purchase_order': this.data.purchase_order,
            'delivery_date': this.data.fecha,
            "consolidar_carga": this.data.consolidar_carga
          }
      ).then((response) => {
        this.skus = (response.data.data.skus)
      }).catch((error) => {
        this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
      });
    },
    getShipTos() {
      axiosInstance.get(this.$woe_api_uri + '/catalog/ship-tos',
          {}
      ).then((response) => {
        this.shiptos = response.data.data.ship_tos;
        if (this.shiptos.length > 0 && this.validateCountries('colombia')) {
          this.data.lugar_entrega = this.shiptos[0];
          this.selectedShipto = this.shiptos[0].ship_to;
          if (this.selectedShipto) this.getPlants();
        }
      }).catch((error) => {
        console.log('error-getShipTos')
        console.log(error)
        this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
      });
    },
    getIncoterms() {

      if (sessionStorage.getItem('country') != 'Mexico') {
        this.enableIncoterm = false;
        return true;
      }

      axiosInstance.get(this.$woe_api_uri + '/catalog/incoterms',
          {}
      ).then((response) => {
        this.incoterms = (response.data.data.incoterms)
      })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
          });
    },
    async validateStep1() {
      let errors = 0;
      $('#lugar .required-span').addClass('d-none')
      $('#incoterm .required-span').addClass('d-none')
      $('#planta .required-span').addClass('d-none')
      $('#fecha .required-span').addClass('d-none')
      $('#order .required-span').addClass('d-none')

      //Valido lugar entrega
      if (this.data.lugar_entrega == "") {
        $('#lugar .required-span').removeClass('d-none')
        $('#lugar select').addClass('required')
        errors++;
      } else {
        $('#lugar .required-span').addClass('d-none')
        $('#lugar select').removeClass('required')
      }

      //Valido Incoterm
      if (this.enableIncoterm) {
        if (this.data.incoterm == "") {
          $('#incoterm .required-span').removeClass('d-none')
          $('#incoterm select').addClass('required')
          errors++;
        } else {
          $('#incoterm .required-span').addClass('d-none')
          $('#incoterm select').removeClass('required')
        }
      }

      //Valido Planta entrega
      if (this.data.planta == "") {
        $('#planta .required-span').removeClass('d-none')
        $('#planta select').addClass('required')
        errors++;
      } else {
        $('#planta .required-span').addClass('d-none')
        $('#planta select').removeClass('required')
      }

      //Valido Fecha entrega
      if (this.data.fecha == "") {
        $('#fecha .required-span').removeClass('d-none')
        $('#fecha .dp-input-custom').addClass('required')
        errors++;
      }

      //Valido purchase order
      // if (this.data.purchase_order == ""){
      //     $('#order .required-span').removeClass('d-none')
      //     $('#input-purchase-order').addClass('required')
      //     errors++;
      // } else if (!this.isPOvalid(this.data.purchase_order)) {
      if (this.data.purchase_order !== "" && !this.isPOvalid(this.data.purchase_order)) {
        $('#order .invalid-span').removeClass('d-none')
        $('#input-purchase-order').addClass('required')
        errors++;
      } else {
        $('#order .required-span').addClass('d-none')
        $('#input-purchase-order').removeClass('required')
      }

      if (errors > 0) {
        return false;
      }

      await this.getSkus()
      this.goStep2()
    },
    validateStep2() {
      this.submit_disabled = true
      if (this.cart.total_pallets <= 0) {
        this.showAlert('warning', `${this.$t('caution')}!`, this.$t('cart_empty'));
      }
      axiosInstance.post(this.$woe_api_uri + '/cart/preview',
          {
            'ship_to': this.selectedShipto,
            'plant_id': this.data.planta,
          }
      ).then(() => {
        this.step = 3
      })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`.description);
          })
          .finally(() => {
            this.submit_disabled = false;
          });
    },
    getUser() {
      axiosInstance.get(this.$woe_api_uri + '/user')
          .then((response) => {
            this.user = response.data.data.user
            sessionStorage.setItem('pwa-woe-user-type', JSON.stringify(this.user.user_type));
            sessionStorage.setItem('name', JSON.stringify(this.user.name));
            const m = ['metric', 'imperial'];
            this.medicion = this.user.default_measurement_system && m.includes(this.user.default_measurement_system)
                ? this.user.default_measurement_system
                : 'imperial';
          })
          .catch((error) => {
            if (error.code == "ERR_BAD_REQUEST") {
              location.href = route + "login";
            } else {
              this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
            }
          });
    },
    async validateStep3() {
      if (sessionStorage.getItem('country') == 'Calatin') {
        const confirm = await this.termAndCond();
        if (!confirm) return false;
      }
      this.submit_disabled = true;
      axiosInstance.post(this.$woe_api_uri + '/cart/checkout', {
        'ship_to': this.selectedShipto,
        'plant_id': this.data.planta,
      }).then((response) => {
        this.data.order_num = response.data.data.order.order_number
        this.step = 4
      }).catch((error) => {
        this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? 'An unknown error was encountered');
      }).finally(() => {
        this.submit_disabled = false;
      });
    },
    async cancelConfirm() {
      return await this.$vbsModal.confirm({
        title: this.$t("cancel_order"),
        message: this.$t("confirm_cancel_order_text"),
        leftBtnText: this.$t("buttons.cancel"),
        rightBtnText: this.$t("buttons.accept"),
        displayCloseBtn: false
      });
    },
    goStep2() {
      this.step = 2
      this.getCart()
    },
    showCuota() {
      axiosInstance.post(this.$woe_api_uri + '/cart/cuota-info', {'sku': this.selected_sku?.id}
      ).then(async (response) => {
        this.$vbsModal.confirm({
          title: this.$t("cuota_info_modal_title"),
          message: response.data.data.data,
          displayCloseBtn: false,
          leftBtnText: this.$t("buttons.close"),
          rightBtnText: this.$t("buttons.accept")
        });
      }).catch((error) => {
        this.showAlert('warning', `${this.$t('caution')}!`, error.response?.data?.error?.message ?? `${this.$t('unexpected_error')}!`);
      }).finally(() => {
        this.submit_disabled = false;
      });
    },
    async termAndCond() {
      return new Promise((resolve) => {
        this.$vbsModal.open({
          content: TermsAndCond,
          contentEmits: {
            onClose: () => {
              this.$vbsModal.close();
              resolve(false);
            },
            onConfirm: () => {
              this.$vbsModal.close();
              resolve(true);
            }
          },
        });
      });
    },
    validatePO(po) {
      if (!this.isPOvalid(po)) {
        $('#order .invalid-span').removeClass('d-none');
        $('#input-purchase-order').addClass('required');
      } else {
        $('#order .invalid-span').addClass('d-none');
        $('#input-purchase-order').removeClass('required');
      }
    },
    isPOvalid(po) {
      const poValue = po;
      const newValue = poValue.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 34);
      return newValue === poValue;
    },
    validateQuantity(e) {
      let charCode = e.which ?? e.keyCode;
      if (charCode !== 0 && charCode !== 8 && charCode !== 13 &&
          charCode !== 37 && charCode !== 39 && charCode !== 46 &&
          !(charCode >= 48 && charCode <= 57) && !(charCode >= 96 && charCode <= 105)
      ) {
        e.preventDefault();
      }
    },
    parseDate(d) {
      const date = new Date(d);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date.toLocaleDateString();
    },
    validateCountries(countries) {
      return countries.split('|').includes(sessionStorage.getItem('country').toLowerCase());
    },
    handleKeydown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        this.$refs.addCartButton.focus();
      }
    },
    getProductLabel(sku) {
      switch (this.country.toLowerCase()) {
        case 'colombia':
          return `${sku.description} - ${sku.material}`;
        default:
          return `${sku.id} - ${sku.description} - ${sku.material}`;
      }
    }
  },
}
</script>

<style scoped>

@media screen and (max-width: 768px) {
  .custom-select-shiptos {
    font-size: 10px;
  }
}

</style>